// Angular
import { Component, Input, HostBinding, SimpleChanges } from '@angular/core';

@Component({
    selector: 'spacer-element',
    standalone: true,
    imports: [],
    templateUrl: 'spacer.component.html',
    styleUrl: 'spacer.component.scss',
})
export class Spacer {

    @Input() height: string = '16px';
    @Input() border: string = 'none';
    @Input() backgroundColor: string = 'transparent';
    
    @HostBinding('style.--spacer-height') heightVar: string;
    @HostBinding('style.--spacer-border') borderVar: string;
    @HostBinding('style.--spacer-background-color') backgroundColorVar: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['height']) {
            this.heightVar = this.height;
        }

        if (changes['border']) {
            this.borderVar = this.border;
        }

        if (changes['backgroundColor']) {
            this.backgroundColorVar = this.backgroundColor;
        }
    }
}